<template>
    <label
        class="v-label theme--light text-field"
        :for="fieldKey"
        :style="{ flexWrap: noWrap ? 'nowrap' : 'wrap' }"
    >
        <span class="label-wrap">{{ getLabel }}</span>
        <v-text-field
            :id="id || fieldKey"
            :key="id || fieldKey"
            :value="formValue"
            v-bind="$attrs"
            :rules="formRules"
            :error-messages="formErrors"
            outlined
            :hide-details="hideDetails"
            v-on="$listeners"
            @input="(v) => input(v)"
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
                ><slot :name="slot" v-bind="scope"
            /></template>
            <template v-for="(_, slot) of $slots" v-slot:[slot]><slot :name="slot" /></template>
        </v-text-field>
    </label>
</template>

<script>
import { humanizeStr } from '@/helpers/form/formatting'

export default {
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
        form: {
            type: Object,
            default: () => ({
                data: null,
                rules: null,
                errors: [],
            }),
        },
        fieldKey: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        resetValidation: {
            type: Function,
            default: () => {
                return () => {}
            },
        },
        hideDetails: {
            type: String,
            default: 'auto',
        },
        nestedProperty: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: '',
        },
        rules: {
            type: Array,
            default: () => [],
        },
        errorMessages: {
            type: [Array, String],
            default: null,
        },
        noWrap: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        getLabel() {
            return this.label ? this.label : humanizeStr(this.fieldKey)
        },
        formRules() {
            if (this.rules.length) {
                return this.rules
            }

            if (this.nestedProperty && this.form.rules[this.fieldKey]) {
                return this.form.rules[this.fieldKey][this.nestedProperty] || []
            }

            if (this.form.rules) {
                return this.form.rules[this.fieldKey]
            }

            return []
        },
        formErrors() {
            if (this.errorMessages) {
                return this.errorMessages
            }
            if (this.nestedProperty && this.form.errors[this.fieldKey]) {
                return this.form.errors[this.fieldKey][this.nestedProperty]
            }

            return this.form.errors[this.fieldKey]
        },
        formValue() {
            if (this.form.data) {
                return this.nestedProperty
                    ? this.form.data[this.fieldKey][this.nestedProperty]
                    : this.form.data[this.fieldKey]
            }

            return this.value
        },
    },
    methods: {
        input(v) {
            this.$emit('input', v)

            const newVal = this.$attrs.type === 'number' ? parseInt(v) : v
            if (this.form.data) {
                if (this.nestedProperty) {
                    this.form.data[this.fieldKey][this.nestedProperty] = newVal

                    return
                }
                this.form.data[this.fieldKey] = newVal
                this.$delete(this.form.errors, this.fieldKey)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.text-field {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    &::v-deep {
        .v-input {
            max-width: 100%;
            width: 400px;
            :disabled {
                color: rgb(5, 15, 45); //update disabled text color to match other input types
            }
        }
    }
}
.label-wrap {
    // This is the width of the longest
    width: 150px;
    line-height: 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
}
</style>
